/**
 * Alpine JS.
 *
 * Imports and sets up the framework used for responsive menus and site skins.
 *
 * @link https://github.com/alpinejs/alpine
 * @file This file imports and sets up Alpine.js.
 * @since 1.0.0
 */

import Alpine from 'alpinejs'
import { Iodine } from '@kingshott/iodine'

const iodine = new Iodine();
iodine.setErrorMessage(
	'required', wghData.strRequired.replace( '%s', '[FIELD]' ) )
iodine.setErrorMessage(
	'maxLength', wghData.strMaxLength.replace( '%1$s', '[FIELD]' ).replace( '%2$s', '[PARAM]' ) )
iodine.setErrorMessage(
	'email', wghData.strEmail.replace( '%s', '[FIELD]' ) )

Alpine.prefix( 'data-x-' ) /* For W3C validation */

Alpine.data( 'wghMobileNav', () => ( {
	isOpen: false,

	init() {
		this.$watch('isOpen', () => {
			document.documentElement.classList.toggle( 'body-navopen', this.isOpen );
		})
	},
	navBody: {
		['data-x-on:resize.window']() {
			/* EDIT ME! 850 is the value of scss variable $width__sm! */
			this.isOpen = window.innerWidth <= 850 ? false : true;
		},
		['data-x-on:keydown.escape']() {
			this.isOpen = false
		},
	},
	navOpen: {
		['data-x-on:click']() {
			this.isOpen = ! this.isOpen
		},
		['data-x-bind:aria-expanded']() {
			return this.isOpen
		},
	},
	navClose: {
		['data-x-show']() {
			return this.isOpen
		},
		['data-x-on:click']() {
			this.isOpen = false
		},
	},
	navSidebar: {
		['data-x-show']() {
			return this.isOpen
		},
		['data-x-transition'](){},
	},
} ) )

Alpine.data( 'wghSiteSkin', () => ( {
	mySkin: localStorage.getItem( 'wghBGStyle' ) || wghData.defaultSkin,
	skins: wghData.skins,

	siteBg: {
		['data-x-bind:class']() {
			return 'bg-' + this.mySkin
		},
		['data-x-bind:style']() {
			return wghData.skinsDisabled || (this.mySkin == wghData.defaultSkin) ? wghData.bodyStyle : ''
		}
	},
	button: {
		['data-x-bind:disabled']() {
			return this.mySkin == this.skin
		},
		['data-x-bind:aria-pressed']() {
			return this.mySkin == this.skin
		},
		['data-x-bind:class']() {
			let buttonClass = 'button-bg button-bg-' + this.skin
			return this.mySkin == this.skin ? buttonClass + ' active' : buttonClass
		},
		['data-x-bind:aria-label']() {
			return this.skins[this.skin]
		},
		['data-x-on:click']() {
			this.mySkin = this.skin
			localStorage.setItem( 'wghBGStyle', this.skin )
		}
	},
	skinName: {
		['data-x-text']() {
			return this.skins[this.mySkin]
		}
	}
} ) )

Alpine.data( 'wghPlayer', ( track = '' ) => ( {
	playing: false,
	trackName: track,

	init() {
		this.$watch( 'playing', ( value ) => {
			if ( value ) {
				this.$refs.wghPlayerTrack.play();
			} else {
				this.$refs.wghPlayerTrack.pause();
			}
		} )
	},
	playerButton: {
		['data-x-bind:aria-label']() {
			let label = this.playing ? wghData.strPause : wghData.strPlay
			return label.replace( '%s', this.trackName )
		},
		['data-x-html']() {
			return this.playing ? '&#9208;' : '&#x25b6;'
		},
		['data-x-on:click']() {
			this.playing = ! this.playing
		}
	}
} ) )

Alpine.data( 'wghComment', () => ( {
	parentID: 0,
	parentAuthor: null,
	loading: false,
	submitted: false,
	valid: true,
	paramRules: {
		// @TODO needs fix if you don't require author/email
		author: ['required', 'maxLength:245'],
		email: ['required', 'email', 'maxLength:100'],
		comment: ['required', 'maxLength:1000'],
	},
	errors: [],

	reply: {
		['data-x-on:click.prevent']() {
			this.parentID = this.comment_ID
			this.parentAuthor = this.comment_author
			document.getElementById( 'respond' ).scrollIntoView();
		}
	},
	formFeedback: {
		['data-x-show']() {
			return ! this.valid || this.submitted
		},
		['data-x-bind:class']() {
			return ! this.valid ? 'error' : ''
		}
	},
	formMain: {
		['data-x-show']() {
			return ! this.submitted
		},
		['data-x-on:submit.prevent']() {
			this.valid = true
			this.loading = true
			this.errors = []
			for ( const field in this.paramRules ) {
				let input = document.getElementById( field );

				let result = iodine.is( input.value, this.paramRules[field] )
				if (result !== true) {
					this.valid = false
					this.errors.push(
						iodine.getErrorMessage( result, { field: input.getAttribute( 'aria-label' ) } ) )
					input.setAttribute( 'aria-invalid', 'true' )
				} else {
					input.setAttribute( 'aria-invalid', 'false' )
				}
			}

			if ( ! this.valid ) {
				this.loading = false
				this.submitted = false
				this.$refs.feedbackDesc.innerHTML = wghData.strError
			} else {
				let params = new FormData( this.$refs.form )
				fetch( wghData.ajax + '?action=wghcomment', {
					method: 'POST',
					credentials: 'same-origin',
					headers: {
						'Cache-Control': 'no-cache',
					},
					body: params
				} )
				.then( response => response.text() )
				.then( ( response ) => {
					// @TODO if next page then do that
					this.submitted = true
					this.$refs.commentList.innerHTML += response
					this.$refs.feedbackDesc.innerHTML = wghData.strSuccess
				} )
				.finally( () => {
					this.loading = false
				} )
			}
		}
	},
	formTitle: {
		['data-x-text']() {
			return this.parentID == 0 ? wghData.strCommentH4 : wghData.strReplyH4.replace( '%s', this.parentAuthor )
		}
	},
	formCancelReply: {
		['data-x-show']() {
			return this.parentID != 0
		},
		['data-x-on:click']() {
			this.parentID = 0
		}
	}
} ) )

Alpine.data( 'wghTrackback', () => ( {
	copied: false,

	checkbox: {
		['data-x-bind:class']() {
			return this.copied ? 'coupon__checkbox__check-clicked' : ''
		},
		['data-x-on:click']() {
			document.getElementById('wgh_trackback').select(); document.execCommand('copy');
			this.copied = true
		}
	}
} ) )

window.Alpine = Alpine
window.Alpine.start()
